export const translations = {
    es: {
        menuCat1: "Inicio",
        menuCat2: "Nosotros",
        menuCat3: "Servicios",
        menuCat4: "Contacto",
        menuCTA: "¿qué esperas para hornear tus ideas con nosotros?",
        homeTitlel1: "¡Llevamos estrategias",
        homeTitlel2: "creativas a la acción!",
        homeTitlel3: "¡Hacemos que tu",
        homeTitlel4: "empresa brille!",
        homeTitlel5: "¡La receta para",
        homeTitlel6: "alcanzar el éxito!",
        sidebarText: "Agencia boutique de diseño web, marketing digital y redes sociales.",
        banner: "Somos chipa agencia digital",
        sectionHome1: "Chipa es una agencia boutique de diseño web, marketing digital y redes sociales ubicada en Dublín y Buenos Aires. Somos una agencia creativa que trabaja para hacer realidad los deseos de sus clientes mejorando su presencia digital para atraer más prospectos y ventas.",
        sectionHome1p2: "Somos un equipo dinámico de expertos especializados en Diseño Web, Marketing Digital y Redes Sociales. Con un profundo entendimiento del siempre cambiante panorama en línea, combinamos nuestra pasión por la creatividad con nuestra destreza técnica para entregar resultados sobresalientes a nuestros clientes.",
        sectionHome3: "Nuestro apasionado equipo va más allá con tu marca.",
        sectionHome3p1: "Para activar",
        sectionHome3p2: "Cuidamos meticulosamente tu marca con brillantez estratégica.",
        sectionHome3p3: "Para conectar",
        sectionHome3p4: "Creamos estrategias a medida para marcas pequeñas, medianas y grandes para atraer y retener a su público objetivo.",
        sectionHome3p5: "Para crecer",
        sectionHome3p6: "Contamos con la creatividad, experiencia, conocimiento y herramientas para hacer evolucionar cualquier negocio.",

        sectionHome4l1: "Delivery de calidad",
        sectionHome4l2: "Creatividad práctica",
        sectionHome4l3: "Foco en resultados",
        sectionHome4: "¿Buscas un sitio web que refleje la verdadera esencia de tu marca?",
        sectionHome4p2: "¿Deseas tener una presencia en línea multicanal para conectar con tu audiencia?",
        sectionHome5: "¡Aterrizaste en el lugar correcto!",
        ctaHome: "contáctanos",
        titleHomeServices: "Servicios",
        textHomeServices: "¡Hacemos que tu empresa brille!",
        tabHomeS1: "Diseño",
        tabHomeS2: "Marketing",
        tabHomeS3: "Comunicación",
        tabBtn: "ver más",
        tabDesignT: "Sitio web",
        tabDesignTx: "Diseñamos, desarrollamos e instalamos páginas web estéticas y funcionales para comunicar tu marca, productos o servicios.",
        tabDesignT2: "Identidad Web",
        tabDesignT2x: "Creamos desde cero o actualizamos la identidad digital de tu empresa para poder tener el sitio web de tus sueños.",
        tabMarketingT: "Social media marketing",
        tabMarketingTx: "Brindamos consultoría en redes sociales para que tu marca conecte con su comunidad objetivo. Ahorrá tiempo delegando en manos expertas la creación de contenido y moderación.",
        tabMarketingT2: "Estrategia digital ",
        tabMarketingT2x: "Inicial - Análisis de situación y desarrollo de metodologías prácticas para un buen desempeño en canales digitales. Incluye estrategia de posicionamiento en buscadores y pauta online para alcanzar al público correcto. En movimiento - Creamos el mix de prácticas digitales más afín a tu empresa. Ofrecemos la combinación ideal de Email Marketing, Growth Marketing, SEO, SEM, Social Listening, Customer Experience y UX-UI.",
        tabCommT: " Creativa",
        tabCommTx: "Asesoramos en redacción creativa para darle mayor dinamismo a tu ecosistema digital. Con este servicio gestionamos un manual de copywriting y capacitamos al equipo responsable de llevar la comunicación digital de tu empresa.",
        tabCommT2: "Institucional",
        tabCommT2x: "Sabemos lo desafiante que resulta para una empresa estar activos en las redes sociales. Hay comentarios negativos que pueden escalar y afectar a la reputación de tu marca si no son trabajados a tiempo. En respuesta a esto recomendamos nuestra consultoría en relaciones públicas digitales.",
        ctaFooter: "empecemos",
        aboutTitle: "Manifiesto",
        aboutMiniSub: "En chipa agencia digital",
        aboutSubtitle: "CREAMOS EXPERIENCIAS DIGITALES ÚNICAS",
        aboutText: "Somos un equipo apasionado por crear estrategias y diseños digitales que marcan la diferencia. Atendemos las inquietudes del negocio y ofrecemos soluciones reales.",
        aboutM1: "En un mundo con tecnología cada vez más avanzada, el cambio es una constante que nos impulsa a seguir aprendiendo y probando nuevas formas de trabajar.",
        aboutM2: "Nos ocupamos de escuchar atentamente a nuestros clientes e investigar distintas maneras de hacerlos crecer en su industria, ofreciendo todo nuestro conocimiento y experiencia profesional.",
        aboutM3: "Además, siempre encontramos una forma entretenida de hacerlo. Por eso creemos que nuestro manifiesto debe hacer honor a su origen: el chipa, una comida típica y por cierto, muy rica de Corrientes, una Provincia de Argentina.",
        recipeTitle: "Con ustedes presentamos nuestra receta personalizada Chipa.",
        recipeStart: "Ingredientes:",
        recipeI1: "1 taza de estrategia",
        recipeI2: "1 taza de creatividad",
        recipeI3: "1/2 taza de experiencia",
        recipeI4: "2 cucharadas de dinamismo y frescura",
        recipeI5: "150 gramos de simpleza",
        prepTitle: "Preparación de Chipa:",
        prep1: "Tomar un bowl y verter las tazas de experiencia, estrategia y creatividad. Revolver con un cucharón durante 30 minutos o batidora durante 15 minutos.",
        prep2: "Mezclar los 150 gramos de simpleza con 2 cucharadas de dinamismo y frescura. Luego verter esta mezcla dentro del bowl.",
        prep3: "Extraer el contenido del bowl y amasar hasta que esté bien integrado. Luego, envolver con un film y llevarlo a descansar a la heladera durante 30 minutos.",
        prep4: "Pasado ese tiempo, retiramos la masa y vamos a formar las bolitas del chipa.",
        prep5: "Colocar las bolitas de chipa en una placa, introducirlas en una sartén y cocinarlas durante 10 minutos en un horno precalentado a 180°.",
        aboutCTA: "¿Listo para probar esta delicia?",
        aboutTeamTitle: "Equipo",
        aboutTeamSub: "Somos un equipo apasionado por el mundo digital y amante del chipa.",
        aboutTeamJobs: "Gerente de proyectos digitales",
        aboutTeamJobs1: "Diseñadora gráfica y web",
        aboutTeamJobs2: "Creador de contenido",
        servicesTitle: "Servicios",
        servicesDesignTitle: "Diseño Web Personalizado con Identidades Destacadas",
        servicesDesignCopy: "Gracias a la vibrante creatividad de Chipa, creamos identidades de marca y sitios web que se destacan. Al combinar ingredientes de alta calidad como la personalización, empatía y consultoría, logramos la receta perfecta para hacer que tu negocio tenga éxito.",
        servicesDesignCopy2: "Somos un equipo de nómadas digitales con más de 10 años de experiencia en Diseño, Marketing Digital y Comunicación Estratégica, que trabajamos activamente para ofrecer un servicio fresco, sostenible y futurista que no solo ofrece estética sino que también genera resultados.",
        servicesCTA: "Descubre la receta ganadora para el éxito de tu marca. Permitenos elaborar tu identidad digital y amplificar su alcance.",
        servicesMktTitle: "Saborea el éxito digital con nuestro análisis y desarrollo de metodología práctica",
        servicesMktCopy: "¡Convierte tu marca en un plato irresistible con nuestros servicios de Marketing en Redes Sociales! Nuestro equipo de expertos en redes sociales brindará consultoría personalizada para conectarte con tu comunidad objetivo. Ahorra tiempo y deja la creación de contenido y moderación en nuestras manos. Nos encargamos de hornear tus ideas y servirlas en bandeja, para que puedas concentrarte en lo que haces mejor. ¡Deja que el aroma de tu marca se propague por las redes sociales y conquista a tus seguidores!",
        servicesMktCopy2: "Disfruta de nuestra mezcla de prácticas digitales y saborea el éxito. Nuestro equipo de expertos cocinará a fuego lento la combinación perfecta de Email Marketing, Growth Marketing, SEO, SEM, Social Listening, Experiencia del Cliente, y UX/UI para llevar tu empresa al siguiente nivel. No esperes más, atrévete a probar nuestro exquisito Chipa Digital y sorprende a tus clientes con una experiencia irresistible!",
        servicesCommTitle: "Potencia tu presencia digital con nuestra consultoría de escritura creativa y consultoría de relaciones públicas",
        servicesCommCopy: "Dale vida a tu ecosistema digital con un toque de creatividad. Nuestro servicio de consultoría de escritura creativa te ayudará a energizar la comunicación de tu empresa. Desde la creación de un manual de redacción hasta la formación de tu equipo, coceremos tus ideas para hacer tu mensaje atractivo y cautivador. No permitas que tu presencia digital sea insípida, reserva tu lugar en nuestra cocina creativa y despierta el interés de tus clientes.",
        servicesCommCopy2: "En el mundo digital, los comentarios negativos pueden propagarse rápidamente y afectar la reputación de tu marca. No dejes que una crisis en línea te impacte. Nuestra consultoría de relaciones públicas digitales te proporcionará las herramientas necesarias para gestionar y manejar esos comentarios de manera oportuna. Deja que nuestro equipo de expertos sea tu aliado digital y prepare una estrategia infalible para proteger la imagen de tu marca. No esperes más, reserva ahora y saborea el éxito en el mundo digital.",
        contactTitle: "Contacto",
        contactCTA: "Contáctanos:",
        contactForm1: "Nombre y Apellido",
        contactForm2: "Mensaje",
        contactFormTitle: "¿Estas listo para crear?"
    },
    en: {
        menuCat1: "Home",
        menuCat2: "About",
        menuCat3: "Services",
        menuCat4: "Contact",
        menuCTA: "Start baking your ideas with us!",
        homeTitlel1: "We bring creative ",
        homeTitlel2: "strategies into action!",
        homeTitlel3: "We make your",
        homeTitlel4: "business shine!",
        homeTitlel5: "The recipe",
        homeTitlel6: "to achieve success!",
        sidebarText: "Boutique agency of web design, digital marketing, and social media.",
        banner: "We are chipa digital agency",
        sectionHome1: "Chipa is a boutique web design, digital marketing and social media agency located in Dublin and Buenos Aires. We are a creative agency that works to make our clients' wishes come true by improving their digital presence to attract more leads and sales.",
        sectionHome1p2: "We are a dynamic team of experts specialized in Web Design, Digital Marketing and Social Media. With a deep understanding of the ever-evolving online landscape, we combine our passion for creativity with our technical prowess to deliver outstanding results for our clients.",
        sectionHome3: "Our passionate team goes above and beyond with your brand.",
        sectionHome3p1: "To activate",
        sectionHome3p2: "We shower your brand with meticulous care and strategic brilliance.",
        sectionHome3p3: "To connect",
        sectionHome3p4: "We create tailor-made strategies for small, medium, and large brands to attract and retain their target audiences.",
        sectionHome3p5: "To grow",
        sectionHome3p6: "We have the creativity, experience, knowledge, and tools to make any business evolve.",
        sectionHome4l1: "Quality delivery",
        sectionHome4l2: "Practical creativity",
        sectionHome4l3: "Focus on results",
        sectionHome4: "Looking for a Website that reflects the true essence of your brand?",
        sectionHome4p2:"Seeking to have a multichannel online presence to connect with your audience?",
        sectionHome5: "You landed in the right place!",
        ctaHome: "contact us",
        titleHomeServices: "Services",
        textHomeServices: "We make your business shine!",
        tabHomeS1: "Design",
        tabHomeS2: "Marketing",
        tabHomeS3: "Communication",
        tabBtn: "view more",
        tabDesignT: "Website",
        tabDesignTx: "Design, develop and install aesthetic and functional web pages to communicate your brand, products or services.",
        tabDesignT2: "Web Identity",
        tabDesignT2x: "Create from scratch or update the digital identity of your company in order to have the website of your dreams.",
        tabMarketingT: "Social media marketing",
        tabMarketingTx: "We provide consultancy in social networks so that your brand connects with its target community. Save time by delegating content creation and moderation to expert hands.",
        tabMarketingT2: "Digital strategy",
        tabMarketingT2x: "Initial - Situation analysis and development of practical methodologies for good performance in digital channels. Includes search engine positioning strategy and online guidelines to reach the right audience. On the move - We create the mix of digital practices that is most in tune with your company. We offer the ideal combination of Email Marketing, Growth Marketing, SEO, SEM, Social Listening, Customer Experience and UX-UI.",
        tabCommT: "Creative",
        tabCommTx: "We advise on creative writing to give your digital ecosystem more dynamism. With this service, we manage a copywriting manual and train the team responsible for carrying out your company's digital communication.",
        tabCommT2: "Institutional",
        tabCommT2x: "We know how challenging it is for a business to be active on social media. There are negative comments that can escalate and affect the reputation of your brand if they are not dealt with on time. In response to this we recommend our consultancy in digital public relations.",
        ctaFooter: "let's begin",
        aboutTitle: "Manifest",
        aboutMiniSub: "At chipa digital agency",
        aboutSubtitle: "WE CREATE UNIQUE DIGITAL EXPERIENCES",
        aboutText: "We are a team passionate about creating digital strategies and designs that make a difference. We attend to business concerns and offer real solutions. ",
        aboutM1: "In a world with increasingly advanced technology, change is a constant that drives us to continue learning and trying new ways of working.",
        aboutM2: "We take care of carefully listening to our clients and researching different ways to help them grow in their industry, offering all our knowledge and professional experience.",
        aboutM3: "Also, we always find an entertaining way to do it. That is why we believe that our manifesto must honor its origin: the chipa, a typical food and by the way, very tasty from Corrientes, a Province of Argentina.",
        recipeTitle: "Presenting our custom chipa recipe.",
        recipeStart: "Ingredients:",
        recipeI1: "1 cup of strategy",
        recipeI2: "1 cup of creativity",
        recipeI3: "1/2 cup of experience",
        recipeI4: "2 tablespoons of dynamism and freshness",
        recipeI5: "150 grams of simplicity",
        prepTitle: "Chipa preparation:",
        prep1: "Grab a bowl and pour the cups of experience, strategy, and creativity. Stir with a ladle for 30 minutes or blender for 15 minutes.",
        prep2: "Mix 150 grams of simplicity with 2 tablespoons of dynamism and freshness. Then pour this mixture into the bowl.",
        prep3: "Extract the contents of the bowl and knead until well integrated. Then wrap with a film and take it to rest in the fridge for 30 minutes.",
        prep4: "After that time, we remove the dough and we are going to form the chipa balls.",
        prep5: "Place the chipa balls on a plate, put them in a frying pan and cook them for 10 minutes in a preheated oven at 180°.",
        aboutCTA: "Ready to try this delight?",
        aboutTeamTitle: "Team",
        aboutTeamSub: "We are a team passionate about the digital world and chipa lover.",
        aboutTeamJobs: "Digital Project Manager",
        aboutTeamJobs1: "Graphic & Web Designer",
        aboutTeamJobs2: "Content Creator",
        servicesTitle: "Services",
        servicesDesignTitle: "Customized Web Design with Highlighted Identities",
        servicesDesignCopy: "Thanks to our Chipa’s vibrant creativity we make brand identities and websites that stand out. By combining high-quality ingredients such as customization, empathy and consultancy, we achieve the perfect recipe to make your business succeed.",
        servicesDesignCopy2: "We are a team of digital nomads with over 10 years of experience in Design, Digital Marketing and Strategic Communication, that actively work to offer a fresh, sustainable and futuristic service that not only deliver aesthetics but also generate results.",
        servicesCTA: "Discover the winning recipe for your brand’s success. Let us craft your digital identity and amplify its reach.",
        servicesMktTitle: "Savor digital success with our analysis and practical methodology development",
        servicesMktCopy: "Turn your brand into an irresistible dish with our Social Media Marketing services! Our team of experts in social media will provide personalized consultancy to connect with your target community. Save time and leave the creation of content and moderation in our hands. We take care of baking your ideas and serving them on a platter, so you can focus on what you do best. Let the aroma of your brand spread across social media and conquer your followers!",
        servicesMktCopy2: "Indulge in our blend of digital practices and taste success! Our team of experts will slow-cook the perfect combination of Email Marketing, Growth Marketing, SEO, SEM, Social Listening, Customer Experience, and UX/UI to take your company to the next level. Don't wait any longer, dare to try our exquisite Digital Chipa and surprise your customers with an irresistible experience!",
        servicesCommTitle: "Boost your digital presence with our creative writing consultancy and public relations consulting",
        servicesCommCopy: "Bring your digital ecosystem to life with a touch of creativity. Our creative writing consultancy service will help you energize your company's communication. From creating a copywriting manual to training your team, we will cook up your ideas to make your message engaging and captivating. Don't let your digital presence be bland, reserve your spot in our creative kitchen and spark the interest of your clients.",
        servicesCommCopy2: "In the digital world, negative comments can spread rapidly and affect your brand's reputation. Don't let an online crisis impact you. Our digital public relations consulting will provide you with the necessary tools to manage and handle those comments in a timely manner. Let our team of experts be your digital ally and prepare an infallible strategy to protect your brand's image. Don't wait any longer, reserve now and savor success in the digital world.",
        contactTitle: "Contact",
        contactCTA: "Get in touch:",
        contactForm1: "Full name",
        contactForm2: "Message",
        contactFormTitle: "Ready to create?"
    },
};